<template>
  <div id="media-modal" class="p-0 m-0" uk-modal>
    <div
        class="uk-modal-dialog rounded-lg w-full shadow-2xl">
      <button class="uk-modal-close-default m-3" type="button" uk-close></button>
      <div class="uk-modal-header dark-bg">
        <h2 class="uk-modal-title">Thư viện media</h2>
      </div>

      <div class="uk-modal-body pt-0">
        <div class="lg:flex lg:space-x-10">
          <div class="lg:w-4/5">
            <nav class="responsive-nav pl-2 is_ligh -mb-0.5 border-transparent">
              <ul  uk-switcher="connect: #media-tab; animation: uk-animation-fade">
                <li><a href="#">Tải lên</a></li>
                <li><a href="#">Hình ảnh<span>{{ images.paginatorInfo.total }}</span></a></li>
                <li><a href="#">Audio<span>{{ audios.paginatorInfo.total }}</span></a></li>
                <li><a href="#">Video<span>{{ videos.paginatorInfo.total }}</span></a></li>
              </ul>
            </nav>
            <div id="media-tab" class="uk-switcher">
              <div style="height: 100%">
                <div class="dropzone-wrap p-3">
                  <div
                      @dragenter.prevent="toggleActive"
                      @dragleave.prevent="toggleActive"
                      @dragover.prevent
                      @change="selectedFile"
                      @drop.prevent="drop"
                      :class="{ 'active-dropzone': active }"
                      class="dropzone p-2 mb-4"
                  >
                    <label :for="'dropzoneFile' + unique">Chọn file</label>
                    <span>Hoặc kéo thả vào đây</span>
                    <span v-if="uploadStatus === 'uploading' || uploadStatus === 'converting'">{{ uploadText }} <span v-if="uploadStatus === 'uploading'">{{ uploadPercent }}%</span></span>
                    <span v-if="message" class="text-xs">{{ message }}</span>
                    <input type="file" :id="'dropzoneFile' + unique" class="dropzoneFile" />
                  </div>
                </div>
              </div>
              <div data-simplebar>
                <div class="grid md:grid-cols-8 grid-cols-2 gap-3 mt-5">
                  <div :uk-tooltip="'title: ' + file.title" v-for="file in images.data" :key="'file' + file.id" v-on:click="setCurrentMedia(file)" class="cursor-pointer">
                    <div class="bg-green-400 max-w-full lg:h-20 h-20 rounded-lg relative overflow-hidden shadow uk-transition-toggle">
                      <img :src="file.url" class="w-full h-full absolute object-cover inset-0">
                      <div class="-bottom-12 absolute bg-gradient-to-b from-transparent h-1/2 to-gray-800 uk-transition-slide-bottom-small w-full"></div>
                    </div>
                  </div>
                </div>
                <div class="mt-6">
                  <v-pagination
                      class="justify-content-center"
                      v-if="images.paginatorInfo.lastPage > 1"
                      v-model="images.paginatorInfo.currentPage"
                      :pages="images.paginatorInfo.lastPage"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="loadImages"
                  />
                </div>
              </div>
              <div data-simplebar>
                <div v-if="audios.data && audios.data.length" class="divide-y">
                  <div v-for="file in audios.data" :key="'file' + file.id" v-on:click="setCurrentMedia(file)" class="cursor-pointer lg:flex lg:space-x-6 py-2">{{ file.title }}</div>
                </div>
                <div class="mt-6">
                  <v-pagination
                      class="justify-content-center"
                      v-if="audios.paginatorInfo.lastPage > 1"
                      v-model="audios.paginatorInfo.currentPage"
                      :pages="audios.paginatorInfo.lastPage"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="loadAudios"
                  />
                </div>
              </div>
              <div data-simplebar>
                <div v-if="videos.data && videos.data.length" class="divide-y">
                  <div v-for="file in videos.data" :key="'file' + file.id" v-on:click="setCurrentMedia(file)" class="cursor-pointer lg:flex lg:space-x-6 py-2">{{ file.title }}</div>
                </div>
                <div class="mt-6">
                  <v-pagination
                      class="justify-content-center"
                      v-if="videos.paginatorInfo.lastPage > 1"
                      v-model="videos.paginatorInfo.currentPage"
                      :pages="videos.paginatorInfo.lastPage"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="loadVideos"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="lg:w-1/5 full pt-6">
            <div v-if="currentMedia && currentMedia.url">
              <img :src="currentMedia.url" class="w-full object-cover rounded-lg"/>
              <p>{{ cutStringFileName(currentMedia.title, 20) }}</p>
              <p>{{ timeago(currentMedia.created_at) }}</p>
            </div>
            <div v-if="currentMedia && currentMedia.audio_url">
              <player :data-setup="dataSetup" :options="playerOptions" :key="currentMedia.id" type="audio"/>
              <p>{{ cutStringFileName(currentMedia.title, 20) }}</p>
              <p>{{ timeago(currentMedia.created_at) }}</p>
            </div>
            <div v-if="currentMedia && currentMedia.video_url">
              <player :data-setup="dataSetup" :options="playerOptions" :key="currentMedia.id" type="video"/>
              <p>{{ cutStringFileName(currentMedia.title, 20) }}</p>
              <p>{{ timeago(currentMedia.created_at) }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-modal-footer text-right dark-bg">
        <button class="button uk-modal-close" type="button" v-if="currentMedia" v-on:click="insertMedia">Chèn vào bài viết</button>
        <button class="button uk-modal-close" type="button" v-else>Thoát</button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {cutStringFileName} from "@/core/services/utils.service";
import {timeago} from "../../services/utils.service";
import Player from "../../../components/Player";

export default {
  name: "Media",
  components: {
    Player
  },
  props: {
    mediaMode: String,
    mediaType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentMedia: null,
      audios: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      videos: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      images: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      editorId: "",
      dataSetup: '{"poster": "https://static.bcdcnt.net/assets/images/bcdcnt-bg.png", "fluid": true}',
      playerOptions: {
        autoplay: false,
        loop: false,
        controls: true,
        bigPlayButton: true,
        sources: []
      },
      unique: Math.round(Math.random() * 1000),
      key: "",
      active: false,
      dropzoneFile: null,
      image: "",
      uploadType: null,
      uploadPercent: 0,
      uploadStatus: "",
      uploadText: "",
      message: ""
    }
  },
  mounted() {
    this.loadAudios();
    this.loadVideos();
    this.loadImages();
  },
  methods: {
    loadAudios() {
      let query = `query($page: Int) {
        me {
          files(first: 10, page: $page, orderBy: [{column: "id", order: DESC}], where: {AND: [{column: "type", value: "audio"}]}) {
            data {
              id
              title
              audio_url
              created_at
            }
            paginatorInfo {
              currentPage
              total
              lastPage
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {page: this.audios.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.me && data.data.me.files) {
              this.audios = data.data.me.files;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadVideos() {
      let query = `query($page: Int) {
        me {
          files(first: 10, page: $page, orderBy: [{column: "id", order: DESC}], where: {AND: [{column: "type", value: "video"}]}) {
            data {
              id
              title
              video_url
              created_at
            }
            paginatorInfo {
              currentPage
              total
              lastPage
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {page: this.videos.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.me && data.data.me.files) {
              this.videos = data.data.me.files;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadImages() {
      let query = `query($page: Int) {
        me {
          images(first: 48, page: $page, orderBy: [{column: "id", order: DESC}]) {
            data {
              id
              title
              url
              created_at
            }
            paginatorInfo {
              currentPage
              total
              lastPage
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {page: this.images.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.me && data.data.me.images) {
              this.images = data.data.me.images;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    setCurrentMedia(media) {
      this.currentMedia = media;
      if (this.currentMedia.audio_url) {
        this.playerOptions.sources[0] = {
          src: this.currentMedia.audio_url,
          type: "audio/mp3"
        };
      } else if (this.currentMedia.video_url) {
        this.playerOptions.sources[0] = {
          src: this.currentMedia.video_url,
          type: "video/mp4"
        };
      }
    },
    setMedia() {
      window.postMessage({action: "setMedia", editorId: this.editorId, data: JSON.parse(JSON.stringify(this.currentMedia))},
          window.location.origin);
    },
    cutStringFileName(str, len) {
      return cutStringFileName(str, len);
    },
    deleteMedia() {
      let query = `mutation($id: ID!) {
        deleteMedia(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.currentMedia.id})
          .then(({data}) => {
            if (data.data && data.data.deleteMedia) {
              this.currentMedia = null;
              this.loadAudios();
              alert("Xóa media thành công");
            } else {
              alert(data.errors[0].message);
            }
          })
          .catch((response) => {
            alert(response.message);
          });
    },
    openConfirmDeleteMedia() {
      if (confirm("Bạn chắc chắn muốn xóa media này?")) {
        this.deleteMedia();
      }
    },
    timeago(x) {
      return timeago(x);
    },
    selectedFile() {
      this.dropzoneFile = document.querySelector("#dropzoneFile" + this.unique).files[0];
    },
    toggleActive() {
      this.active = !this.active;
    },
    handleUpload() {
      this.message = "";
      if (this.dropzoneFile) {
        if (this.uploadType === "audio" || this.uploadType === "video") {
          this.uploadStatus = "uploading";
          this.uploadText = "Đang upload...";
          ApiService.uploadMusic(this.dropzoneFile, "editor", {onUploadProgress: this.onUploadProgress})
              .then(({data}) => {
                if (data.success) {
                  if (this.uploadType === "audio") {
                    this.loadAudios();
                  } else {
                    this.loadVideos();
                  }
                  this.setCurrentMedia(data.data);
                  this.uploadStatus = "finished";
                } else {
                  this.message = data.message;
                  this.uploadStatus = "finished";
                }
              })
              .catch((response) => {
                console.log(response);
                this.message = response.message;
                this.uploadStatus = "finished";
              });
        } else if (this.uploadType === "image") {
          ApiService.uploadImage(this.dropzoneFile, "editor", {onUploadProgress: this.onUploadProgress})
              .then(({data}) => {
                if (data.success) {
                  this.loadImages();
                  this.setCurrentMedia(data.data);
                  this.uploadStatus = "finished";
                } else {
                  console.log(data.message);
                  this.message = data.message;
                  this.uploadStatus = "finished";
                }
              })
              .catch((response) => {
                console.log(response);
                this.message = response.message;
                this.uploadStatus = "finished";
              });
        }
      }
    },
    drop(e) {
      this.toggleActive();
      this.dropzoneFile = e.dataTransfer.files[0];
    },
    onUploadProgress(progressEvent) {
      this.uploadPercent = Math.round((progressEvent.loaded / progressEvent.total ) * 100);
      if (this.uploadPercent === 100 && this.type === "music") {
        this.uploadText = "Đang convert...";
        this.uploadStatus = "converting";
      }
    },
    removeObject() {
      this.objectId = null;
      this.dropzoneFile = null;
    },
    getTypeFromMimeType(mimeType) {
      let tmp = mimeType.split("/");
      return tmp[0];
    },
    insertMedia() {
      window.postMessage({action: "insertMedia", editorId: this.editorId, media: JSON.parse(JSON.stringify(this.currentMedia))},
          window.location.origin);
    }
  },
  created() {
    window.addEventListener("message", (event) => {
      if (event.data.action === "openMedia") {
        window.$("#media-modal").addClass("uk-modal uk-open").css({display: "block"});
        window.$("html").addClass("uk-modal-page");
        this.editorId = event.data.editor_id;
      }
    }, false);
  },
  watch: {
    dropzoneFile: {
      handler(newVal) {
        this.uploadType = this.getTypeFromMimeType(newVal.type);
        this.handleUpload();
      }
    },
    playerOptionsProp: {
      handler(newVal) {
        this.playerOptions = newVal;
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.dropzone-wrap {
  width: 100%;
  height: 100%;
}
.dropzone {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  border: 2px dashed #41b883;
  background-color: #fff;
  transition: 0.3s ease all;
}

.dropzone label {
  padding: 8px 12px;
  color: #fff;
  background-color: #41b883;
  transition: 0.3s ease all;
  border-radius: 10px;
}

.dropzone label:hover {
  cursor: pointer;
}

.dropzone input {
  display: none;
}

.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: #41b883;
}

.active-dropzone label {
  background-color: #fff;
  color: #41b883;
}
</style>
