import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import mediaIcon from '@ckeditor/ckeditor5-ckfinder/theme/icons/browse-files.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

class MediaPlugin extends Plugin {
  init() {
    const editor = this.editor;

    window.addEventListener("message", (event) => {
      if (event.data.action === "insertMedia" && event.data.editorId === editor.id) {
        editor.model.change(writer => {
          if (event.data.media.url) {
            const imageElement = writer.createElement('imageBlock', {
              src: event.data.media.url
            });

            editor.model.insertContent(imageElement, editor.model.document.selection);
          } else if (event.data.media.audio_url || event.data.media.video_url) {
            let url = event.data.media.audio_url || event.data.media.video_url;
            editor.execute( 'mediaEmbed', url);
          }
        });
      }
    }, false);

    editor.ui.componentFactory.add('mediaPlugin', locale => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Chèn audio, video, hình ảnh',
        icon: mediaIcon,
        tooltip: true
      });

      view.on('execute', () => {
        window.postMessage({action: "openMedia", editor_id: editor.id},
          window.location.origin);
      });

      return view;
    });
  }
}

export default MediaPlugin;
