import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import UploadImageAdapter from "./UploadImageAdapter";

class UploadImageAdapterPlugin extends Plugin {
  init() {
    this.editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadImageAdapter(loader);
    };
  }
}

export default UploadImageAdapterPlugin;
