import {getToken} from "@/core/services/jwt.service";

export default class UploadImageAdapter {
  constructor( loader ) {
    this.loader = loader;
    console.log("loader........", loader);
    console.log("loader........", typeof loader._adapter);
  }

  upload() {
    return this.loader.file
      .then( file => new Promise( ( resolve, reject ) => {
        this._initRequest();
        this._initListeners( resolve, reject, file );
        this._sendRequest( file );
      } ) );
  }

  abort() {
    if ( this.xhr ) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();

    xhr.open( 'POST', process.env.VUE_APP_IMG_API_URL + "/upload", true );
    let token = getToken();
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.responseType = 'json';
  }

  _initListeners( resolve, reject, file ) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${ file.name }.`;

    xhr.addEventListener( 'error', () => reject( genericErrorText ) );
    xhr.addEventListener( 'abort', () => reject() );
    xhr.addEventListener( 'load', () => {
      const response = xhr.response;

      if ( !response || response.message ) {
        return reject( response && response.message ? response.message : genericErrorText );
      }

      resolve( {
        default: response.data.url
      } );
    } );

    if ( xhr.upload ) {
      xhr.upload.addEventListener( 'progress', evt => {
        if ( evt.lengthComputable ) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      } );
    }
  }

  _sendRequest( file ) {
    const data = new FormData();
    data.append( 'file', file );
    this.xhr.send( data );
  }
}
